@font-face {
  font-family: 'PT Root UI';
  font-weight: normal;
  src: url('../fonts/PT-Root-UI-Regular.ttf');
}

@font-face {
  font-family: 'PT Root UI';
  font-weight: 500;
  src: url('../fonts/PT-Root-UI-Medium.ttf');
}

@font-face {
  font-family: 'PT Root UI';
  font-weight: bold;
  src: url('../fonts/PT-Root-UI-Bold.ttf');
}
